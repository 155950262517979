<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="invoiceData === undefined">
      <h4 class="alert-heading">Error fetching invoice data</h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
          Invoice List
        </b-link>
        for other invoices.
      </div>

    </b-alert>

    <b-row v-if="invoiceData" class="invoice-add">
      <b-col cols="12" xl="9" md="8">
        <validation-observer ref="milestoneInvoiceValidation">
          <b-form v-on:submit.prevent="milestoneInvoiceValidationForm">
            <b-card no-body class="invoice-preview-card">

              <!--              Invoice Header Redesign Starts Here -->
              <b-card-body class="invoice-padding pb-0">
                <div v-if="showLogo" class="d-flex align-items-center justify-content-center">
                  <div class="logo-wrapper">
                    <template v-if="companyLogo">
                      <img :src="companyLogo" alt="Company Logo" height="160" class="mb-3" />
                    </template>
                  </div>
                </div>

                <b-row class="invoice-spacing">
                  <!-- To Section -->
                  <b-col cols="12" md="4">
                    <h1 class="mb-3">INVOICE</h1>
                    <b>{{ milestoneInfo?.project?.data?.lead?.data?.company_name }}</b>
                    <div v-if="showContactPerson && milestoneInfo?.project?.data?.lead?.data?.key_contact_person">
                      {{ milestoneInfo?.project?.data?.lead?.data?.key_contact_person }}
                    </div>
                    <div v-if="showAddress && milestoneInfo?.project?.data?.lead?.data?.address">
                      {{ milestoneInfo?.project?.data?.lead?.data?.address }}
                    </div>
                    <div v-if="showEmail && milestoneInfo?.project?.data?.lead?.data?.email">
                      {{ milestoneInfo?.project?.data?.lead?.data?.email }}
                    </div>
                    <div v-if="showMobile && milestoneInfo?.project?.data?.lead?.data?.mobile">
                      {{ milestoneInfo?.project?.data?.lead?.data?.mobile }}
                    </div>
                  </b-col>

                  <!-- Date Section -->
                  <b-col cols="12" md="4" class="text-md-left">
                    <div class="mb-3">
                      <h6 class="invoice-title">Invoice No.</h6>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input id="invoice-data-id" v-model="invoiceId" disabled />
                      </b-input-group>
                    </div>

                    <div class="mb-3">
                      <h6 class="invoice-title">Invoice Date</h6>
                      <ValidationProvider name="issue date" v-slot="{ errors }" vid="issue_date" rules="required">
                        <flat-pickr v-model="issueDate" class="form-control invoice-edit-input"
                                    :disabled="invoiceInfo?.status !== 'due'" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>

                    <div class="mb-3">
                      <h6 class="invoice-title">Due Date</h6>
                      <ValidationProvider name="due date" v-slot="{ errors }" vid="due_date" rules="required">
                        <flat-pickr v-model="dueDate" class="form-control invoice-edit-input"
                                    :disabled="invoiceInfo?.status !== 'due'" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </b-col>

                  <!-- From Section -->
                  <b-col cols="12" md="4" class="text-center text-md-left">
                    <h6 class="mb-3">{{ companyName }}</h6>
                    <div v-html="this.officeAddress"></div>
                    <p class="card-text mb-0">Phone: {{ this.officeMobile }}</p>
                    <p class="card-text mb-0">Email: {{ this.officeEmail }}</p>
                  </b-col>
                </b-row>
              </b-card-body>

              <!--              Invoice Header Redesign Ends Here -->

              <!--              while update not available starts here-->
              <template v-if="invoiceInfo?.status !== 'due'">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <div class="table-responsive">
                      <table class="table table-bordered" >
                        <thead class="table-light">
                        <tr>
                          <th style="width: 15%;">SL No.</th>
                          <th style="width: 50%;">Description</th>
                          <th style="width: 5%;">Quantity</th>
                          <th style="width: 15%;">Unit Price ({{ currency }})</th>
                          <th style="width: 15%;" class="text-end">Total ({{ currency }})</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in invoiceData.items" :key="index">
                          <td style="width: 15%;">{{ index + 1 }}</td>
                          <td style="width: 50%;">
                            <div v-if="item.itemTitle">
                              <div class="fw-bold">{{ item.itemTitle }}</div>
                              <p class="text-truncate" style="max-width: 100%;" v-b-tooltip.hover :title="item.description">
                                {{ item.description }}
                              </p>
                            </div>
                          </td>
                          <td style="width: 5%;">{{ item.qty || 0 }}</td>
                          <td style="width: 15%;">{{ item.cost || 0 }}</td>
                          <td style="width: 15%;" class="text-end">
                            {{ item.cost * item.qty || 0 }}
                          </td>
                        </tr>

                        <tr class="border-0">
                          <td colspan="4" class="text-right border-0">Subtotal</td>
                          <td class="text-end"><strong>{{ subTotalAmount }}</strong></td>
                        </tr>

                        <tr>
                          <td colspan="4" class="text-right border-0">VAT</td>
                          <td class="text-end"><strong>{{ vatAmount }}</strong></td>
                        </tr>

                        <tr v-if="showTax">
                          <td colspan="4" class="text-right border-0">TAX</td>
                          <td class="text-end"><strong>{{ taxAmount }}</strong></td>
                        </tr>

                        <!--                        <tr class="border-0">-->
                        <!--                          <td colspan="4" class="text-right"><strong>Discount</strong></td>-->
                        <!--                          <td class="text-end"><strong>{{ currency }} {{ discountAmount }}</strong></td>-->
                        <!--                        </tr>-->

                        <tr>
                          <td colspan="4" class="text-right border-0">Grand Total</td>
                          <td class="text-end text-danger"><strong>{{ grandTotalAmount ?? 0 }}</strong></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </b-card-body>
              </template>
              <!--              while update not available ends here-->

              <!--              while update available starts here-->
              <template v-if="invoiceInfo?.status === 'due'">
                <b-card-body class="invoice-padding form-item-section">
                  <div
                      ref="form"
                      class="repeater-form"
                      :style="{ height: trHeight }"
                  >
                    <b-row
                        v-for="(item, index) in invoiceData.items"
                        :key="index"
                        ref="row"
                        class="pb-2"
                    >
                      <b-col cols="12">
                        <div class="d-none d-lg-flex">
                          <b-row class="flex-grow-1 px-1">
                            <b-col cols="12" lg="5"> Description </b-col>
                            <b-col cols="12" lg="3"> Unit Price </b-col>
                            <b-col cols="12" lg="2"> Quantity </b-col>
                            <b-col cols="12" lg="2"> Total </b-col>
                          </b-row>
                          <div class="form-item-action-col" />
                        </div>

                        <div class="d-flex border rounded">
                          <b-row class="flex-grow-1 p-2">
                            <!-- Single Item Form Headers -->
                            <b-col cols="12" lg="5">
                              <b-form-group>
                                <ValidationProvider
                                    name="item"
                                    v-slot="{ errors }"
                                    vid="item"
                                    rules="required"
                                >
                                  <b-form-input
                                      v-model="item.itemTitle"
                                      type="text"
                                  />
                                  <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="3">
                              <b-form-group>
                                <ValidationProvider
                                    name="amount"
                                    v-slot="{ errors }"
                                    vid="amount"
                                    rules="required|min_value:0"
                                >
                                  <b-form-input
                                      v-model="item.cost"
                                      type="number"
                                  />
                                  <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="2">
                              <b-form-group>
                                <ValidationProvider
                                    name="quantity"
                                    v-slot="{ errors }"
                                    vid="quantity"
                                    rules="required|min_value:1"
                                >
                                  <b-form-input
                                      v-model="item.qty"
                                      type="number"
                                  />
                                  <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>
                            <b-col cols="12" lg="2">
                              <label class="d-inline d-lg-none">Price</label>
                              <p class="mb-1">
                                {{ item.cost * item.qty }} {{ currency }}
                              </p>
                            </b-col>
                            <b-col cols="12" lg="5">
                              <b-form-group>
                                <ValidationProvider
                                    name="description"
                                    v-slot="{ errors }"
                                    vid="description"
                                >
                                  <b-form-textarea
                                      v-model="item.description"
                                      class="mb-lg-0"
                                  />
                                  <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                </ValidationProvider>
                              </b-form-group>

                              <label class="d-inline d-lg-none"
                              >Description</label
                              >
                            </b-col>
                          </b-row>

                          <div
                              class="d-flex flex-column justify-content-between border-left py-50 px-25"
                          >
                            <feather-icon
                                size="16"
                                icon="XIcon"
                                class="cursor-pointer"
                                @click="
                              invoiceData.items.length > 1
                                ? removeItem(index)
                                : null
                            "
                            />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <template v-if="invoiceInfo?.status == 'due'">
                    <div class="mt-1">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          size="sm"
                          variant="primary"
                          @click="addNewItemInItemForm"
                      >
                        Add Item
                      </b-button>
                      <b-button
                          type="submit"
                          class="float-right"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          size="sm"
                          variant="primary"
                      >
                        Update
                      </b-button>
                    </div>
                  </template>
                </b-card-body>
                <b-card-body class="invoice-padding pb-0">
                  <b-row>
                    <b-col
                        cols="12"
                        md="6"
                        class="mt-md-0 mt-3 d-flex align-items-center"
                        order="2"
                        order-md="1"
                    >
                    </b-col>

                    <b-col
                        cols="12"
                        md="6"
                        class="mt-md-6 d-flex justify-content-end"
                        order="1"
                        order-md="2"
                    >
                      <div class="invoice-total-wrapper custom-width">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Sub Total:</p>
                          <p class="invoice-total-amount">
                            {{ currency }} {{ subTotalAmount }}
                          </p>
                        </div>
                        <!-- <div class="invoice-total-item">
                          <p class="invoice-total-title">
                            <b-form-checkbox
                              v-model="vatTaxSelected"
                              @change="handleCheckboxClick"
                            >
                              <b>Include Vat Tax</b>
                            </b-form-checkbox>
                          </p>
                        </div> -->

                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Vat:</p>

                          <b-form-group>
                            <ValidationProvider
                                name="vat amount"
                                v-slot="{ errors }"
                                vid="vat_amount"
                                rules="required|min_value:0"
                            >
                              <b-input-group :append="currency">
                                <b-form-input v-model="vatAmount" type="number" />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </ValidationProvider>
                          </b-form-group>
                        </div>
                        <div class="invoice-total-item">
                          <p v-if="showTax" class="invoice-total-title">Tax:</p>

                          <b-form-group>
                            <ValidationProvider
                                name="tax amount"
                                v-slot="{ errors }"
                                vid="tax_amount"
                                rules="required|min_value:0"
                            >
                              <b-input-group :append="currency">
                                <b-form-input v-model="taxAmount" type="number" />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </ValidationProvider>
                          </b-form-group>
                        </div>

                        <!--                        <div class="invoice-total-item">-->
                        <!--                          <p class="invoice-total-title">Discount: </p>-->

                        <!--                          <b-form-group>-->
                        <!--                            <ValidationProvider-->
                        <!--                                name="discount amount"-->
                        <!--                                v-slot="{ errors }"-->
                        <!--                                vid="discount_amount"-->
                        <!--                                :rules="'required|min_value:0|max_value:'+ (Number(subTotalAmount) + Number(vatAmount) + Number(taxAmount))"-->
                        <!--                            >-->
                        <!--                              <b-input-group :append="currency">-->
                        <!--                                <b-form-input v-model="discountAmount" type="number" />-->
                        <!--                              </b-input-group>-->
                        <!--                              <small class="text-danger">{{ errors[0] }}</small>-->
                        <!--                            </ValidationProvider>-->
                        <!--                          </b-form-group>-->
                        <!--                        </div>-->

                        <hr class="my-50" />
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Grand Total:</p>
                          <p class="invoice-total-amount">
                            {{ currency }} {{ grandTotalAmount }}
                          </p>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
                <!-- Spacer -->
                <hr class="invoice-spacing" />
                <!-- Note -->
                <b-card-body class="invoice-padding pt-0">
                  <span class="font-weight-bold">Note: </span>
                  <b-form-textarea v-model="remarks" />
                </b-card-body>
              </template>
              <!--              while update not available ends here-->

              <!--              while update not available starts here-->
              <template v-if="invoiceInfo?.status !== 'due'">
                <b-card-body class="invoice-padding pt-0">
                  <div class="mt-5">
                    <h4 class="mb-1" style="text-transform: capitalize;">In Words: {{ currency }} {{ numWords(grandTotalAmount ?? 0) }} Only</h4>
                    <h5 v-if="remarks" class="mb-1">Note/Terms</h5>
                    <b v-if="remarks">{{ remarks ?? "N/A" }}</b>
                  </div>
                </b-card-body>

                <h1 class="text-center my-5">Thank You For Your Business</h1>

                <br>
              </template>
              <!--              while update not available ends here-->


            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <h5 class="mb-30">Print Configuration</h5>

        <b-card>

          <b class="mb-5">Invoice</b>
          <b-col cols="6">
            <b-form-checkbox
                id="customSwitch1"
                v-model="showLogo"
                switch
                class="custom-toggle"
            >
              Show Logo
            </b-form-checkbox>
          </b-col>

          <b class="mb-5">Billing To</b>
          <b-col cols="6">
            <b-form-checkbox
                id="customSwitch2"
                v-model="showContactPerson"
                switch
                class="custom-toggle"
            >
              Contact Person
            </b-form-checkbox>
          </b-col>

          <b-col cols="6">
            <b-form-checkbox
                id="customSwitch3"
                v-model="showAddress"
                switch
                class="custom-toggle"
            >
              Address
            </b-form-checkbox>
          </b-col>

          <b-col cols="6">
            <b-form-checkbox
                id="customSwitch4"
                v-model="showEmail"
                switch
                class="custom-toggle"
            >
              Email
            </b-form-checkbox>
          </b-col>

          <b-col cols="6">
            <b-form-checkbox
                id="customSwitch5"
                v-model="showMobile"
                switch
                class="custom-toggle"
            >
              Mobile No.
            </b-form-checkbox>
          </b-col>

          <b class="mb-5">Pricing</b>
          <b-col cols="6">
            <b-form-checkbox
                id="customSwitch6"
                v-model="showTax"
                switch
                class="custom-toggle"
            >
              Tax
            </b-form-checkbox>
          </b-col>

          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              v-on:click="printInvoice"
          >
            Print
          </b-button>
        </b-card>

        <!-- Action Buttons -->
        <b-card>

          <b-row>
            <b-col cols="6" class="">
              <template v-if="invoiceInfo?.status !== 'due'">
                <b-badge variant="success">Paid</b-badge>
              </template>
              <template v-else>
                <b-badge variant="danger">Due</b-badge>
              </template>
            </b-col>
          </b-row>

          <!-- Button: Add Payment -->
          <template v-if="invoiceInfo?.status == 'due'">
            <b-button
                v-b-toggle.sidebar-invoice-add-payment
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
            >
              Collect Payment
            </b-button>
          </template>

          <b-row class="mt-3">
            <b-col
                cols="12"
                class="mt-xl-0 mt-2 d-xl-flex d-block"
            >
              <div >
                <h6 class="mb-30">Payment Details:</h6>
                <table>
                  <tbody>
                  <div>
                    <tr>
                      <td class="pr-1">Bank Name:</td>
                      <td>{{ accountInfo?.bank_name }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Account Name:</td>
                      <td>{{ accountInfo?.name }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Account Number:</td>
                      <td>
                        {{
                          accountInfo?.account_number
                              ? accountInfo?.account_number
                              : "N/A"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">Routing Number:</td>
                      <td>
                        {{
                          accountInfo?.routing_number
                              ? accountInfo?.routing_number
                              : "N/A"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">Country:</td>
                      <td>{{ accountInfo?.country?.data?.name }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">IBAN:</td>
                      <td>
                        {{
                          accountInfo?.iban_number
                              ? accountInfo?.iban_number
                              : "N/A"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">SWIFT code:</td>
                      <td>
                        {{
                          accountInfo?.swift_code
                              ? accountInfo?.swift_code
                              : "N/A"
                        }}
                      </td>
                    </tr>
                  </div>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col cols="12" xl="12" class="mb-lg-1">
              <h5 class="mb-30">Milestone Info:</h5>

              <p class="card-text mb-25">
                Title: {{ milestoneInfo?.title }}
              </p>

              <p class="card-text mb-25">
                Total Amount: {{ currency }} {{ milestoneInfo?.total_amount }}

              </p>
              <p class="card-text mb-25">
                Total Vat Amount:
                {{ currency }} {{ milestoneInfo?.total_vat_amount }}
              </p>
              <p v-if="showTax" class="card-text mb-25">
                Total Tax Amount:
                {{ currency }} {{ milestoneInfo?.total_tax_amount }}
              </p>
              <p class="card-text mb-25">
                Grand Total Amount:
                {{ currency }} {{ milestoneInfo?.grand_amount }}
              </p>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

    <!-- Printed Invoice  -->
    <div id="content" style="width: 90%;  margin: 0; padding: 0; display: none">
      <div style="width: 90%; max-width: 800px; margin: auto; ">
        <div v-if="showLogo" style="text-align: center; margin-bottom: 20px;">
          <div style="margin-bottom: 20px;">
            <img v-if="companyLogo" :src="companyLogo" alt="Company Logo" height="160" />
          </div>
        </div>
        <div v-else>
          <div style="margin-top: 160px"></div>
        </div>

        <div style="display: flex; justify-content: space-between;">

          <div style="width: 37%; overflow-wrap: break-word;" >
            <h1 style="margin-top: 0px; font-size: 28px">INVOICE</h1>
            <strong>{{ milestoneInfo?.project?.data?.lead?.data?.company_name }}</strong>
            <div v-if="showContactPerson">{{ milestoneInfo?.project?.data?.lead?.data?.key_contact_person }}</div>
            <div v-if="showAddress">{{ milestoneInfo?.project?.data?.lead?.data?.address }}</div>
            <div v-if="showEmail">{{ milestoneInfo?.project?.data?.lead?.data?.email }}</div>
            <div v-if="showMobile">{{ milestoneInfo?.project?.data?.lead?.data?.mobile }}</div>
          </div>

          <div style="text-align: center; width: 26%; overflow-wrap: break-word;">
            <div class="margin-bottom:5px">
              <strong>Invoice No.</strong>
              <div style="padding: 5px;">#{{ invoiceId }}</div>
            </div>
            <br>
            <div class="margin-bottom:5px">
              <strong>Invoice Date</strong>
              <div style="padding: 5px;">{{ issueDate }}</div>
            </div>
            <br>
            <div class="margin-bottom:5px">
              <strong>Due Date</strong>
              <div style="padding: 5px;">{{ dueDate }}</div>
            </div>
          </div>

          <div style="width: 37%; overflow-wrap: break-word;" >
            <strong>{{ companyName }}</strong>
            <br>
            <br>
            <div>{{ officeAddress }}</div>
            <div>Phone: {{ officeMobile }}</div>
            <div>Email: {{ officeEmail }}</div>
          </div>
        </div>

        <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
          <thead>
          <tr style="background-color: #f5f5f5; border: 1px solid black;">
            <th style="padding: 10px; text-align: left; border: 1px solid black; font-size: 14px;">SL No.</th>
            <th style="padding: 10px; text-align: left; width: 40%; border: 1px solid black; font-size: 14px;">Description</th>
            <th style="padding: 10px; text-align: left; border: 1px solid black; font-size: 14px;">Quantity</th>
            <th style="padding: 10px; text-align: right; border: 1px solid black; font-size: 14px;">Unit Price ({{ currency }})</th>
            <th style="padding: 10px; text-align: right; border: 1px solid black; font-size: 14px;">Total ({{ currency }})</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in invoiceData.items" :key="index" style="border: 1px solid black;">
            <td style="text-align: center">{{ index + 1 }}</td>
            <td style="padding: 10px; border: 1px solid black;">
              <div class="font-weight-bold">{{ item.itemTitle }}</div>
              <p class="text-truncate" style="max-width: 100%;" v-b-tooltip.hover :title="item.description">
                {{ item.description }}
              </p>
            </td>
            <td style="padding: 10px; border: 1px solid black;">{{ item.qty || 0 }}</td>
            <td style="padding: 10px;text-align: right; border: 1px solid black;">{{ item.cost || 0 }}</td>
            <td style="padding: 10px; text-align: right; border: 1px solid black;">{{ item.cost * item.qty || 0 }}</td>
          </tr>

          <tr>
            <td colspan="4" style="padding: 10px; text-align: right; border: none">Subtotal</td>
            <td style="padding: 10px; text-align: right; border: 1px solid black;">{{ subTotalAmount }}</td>
          </tr>
          <tr >
            <td colspan="4" style="padding: 10px; text-align: right; border: none">VAT</td>
            <td style="padding: 10px; text-align: right; border: 1px solid black;">{{ vatAmount }}</td>
          </tr>
          <tr v-if="showTax">
            <td colspan="4" style="padding: 10px; text-align: right; border: none">TAX</td>
            <td style="padding: 10px; text-align: right; border: 1px solid black;">{{ taxAmount }}</td>
          </tr>
          <!--          <tr style="border: 1px solid black;">-->
          <!--            <td colspan="3" style="padding: 10px; text-align: right; border: 1px solid black;"><strong>Discount</strong></td>-->
          <!--            <td style="padding: 10px; text-align: right; border: 1px solid black;"><strong>{{ currency }} {{ discountAmount }}</strong></td>-->
          <!--          </tr>-->
          <tr>
            <td colspan="4" style="padding: 10px; text-align: right; border: none">Grand Total</td>
            <td style="padding: 10px; text-align: right; color: red; border: 1px solid black;"><strong>{{ grandTotalAmount ?? 0 }}</strong></td>
          </tr>
          </tbody>
        </table>

        <div style="margin-top: 30px;">
          <h4 style="font-size: 16px;text-transform: capitalize;">In Words: {{ currency }} {{ numWords(grandTotalAmount ?? 0) }} Only</h4>
          <h4 v-if="remarks"><strong>Note/Terms</strong></h4>
          <p v-if="remarks">{{ remarks ?? "N/A" }}</p>
        </div>

        <h2 style="text-align: center; margin-top: 50px; margin-bottom: 50px;">Thank You For Your Business</h2>
        <br>

      </div>
    </div>

    <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment
        :invoiceInfo="invoiceInfo"
        :milestoneInfo="milestoneInfo"
        v-on:loadInvoiceInfo="updateInvoiceInfo"
    />
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Logo from "@core/layouts/components/Logo.vue";
import { mapGetters } from "vuex";
import { INVOICE_PAYMENT_ACCESS } from "@/helpers/permissionsConstant";

import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import router from "@/router/admin/index";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BAlert,
  BLink,
  VBToggle,
  BBadge,
  BTableLite,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
//   import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarSendInvoice from "./InvoiceSidebarSendInvoice.vue";
import InvoiceSidebarAddPayment from "./InvoiceSidebarAddPayment.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  required,
  email,
  integer,
  between,
  min_value,
} from "vee-validate/dist/rules";
import numWords from "num-words";
export default {
  name: "InvoiceDetailsView",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BAlert,
    BLink,
    flatPickr,
    vSelect,
    BTableLite,
    Logo,
    InvoiceSidebarSendInvoice,
    InvoiceSidebarAddPayment,
    ValidationProvider,
    ValidationObserver,
    BBadge,
  },
  data() {
    return {
      itemFormBlankItem: {
        item: null,
        cost: 0,
        qty: 1,
        price: 0,
        description: "",
      },
      invoiceData: {
        items: [
          {
            itemTitle: "",
            cost: 0,
            qty: 1,
            price: 0,
            description: "",
          },
        ],
      },

      INVOICE_PAYMENT_ACCESS,

      invoiceDescription: [],
      showLogo: true,
      showContactPerson: true,
      showAddress: true,
      showEmail: true,
      showMobile: true,
      showTax: true,

      invoiceInfo: "",
      milestoneInfo: "",
      companyName: "",
      officeAddress: "",
      officeEmail: "",
      officeMobile: "",
      accountInfo: "",
      invoiceId: "",
      remarks: "",
      dueDate: "",
      issueDate: "",
      vatAmount: 0,
      discountAmount: 0,
      taxAmount: 0,
      vatFixedAmount: 0,
      taxFixedAmount: 0,
      currency: "",
      invoiceNo: "",
      invoicePrefix: "",
      invoiceCode: "",

      vatTaxSelected: true,
    };
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    "invoiceData.items": function () {
      this.initTrHeight();
    },
  },
  mounted() {
    this.initTrHeight();
  },
  async created() {
    window.addEventListener("resize", this.initTrHeight);

    this.loadItems();
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  computed: {
    ...mapGetters({
      filters: "invoiceModule/getFilters",
      permissions: "userModule/getPermissions",
    }),
    companyLogo() {
      return this.$store.getters["authModule/companyLogo"];
    },
    companyData() {
      return this.$store.getters["companyModule/companyLogo"];
    },
    subTotalAmount() {
      return this.invoiceData.items.reduce(
          (total, item) => total + item.cost * item.qty,
          0
      );
    },
    grandTotalAmount() {
      const subTotal = Number(this.subTotalAmount) || 0;
      const vat = Number(this.vatAmount) || 0;
      const tax = Number(this.taxAmount) || 0;
      const discount = Number(this.discountAmount) || 0;

      return (subTotal + vat + tax) - discount;
    }
  },

  methods: {
    numWords,
    formatDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },
    printInvoice() {
      const printSection = document.getElementById('content');
      const originalContent = document.body.innerHTML;

      // Create a new window for printing
      const newWindow = window.open('', '_blank', 'width=600,height=400');

      // Add styles for printing
      newWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
        <style>
          @page {
              size: auto;
              margin: 20px;
            }

          /* Add any styles you want for the printed content here */
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
          }
          /* Example: If you want page breaks to happen automatically */
          .page-break { page-break-before: always; }

          /* Ensure the print layout is clean and well-organized */
          @media print {
            body {
              margin: 0;
              padding: 0;
              font-size: 14px;
            }
            .no-print {
              display: none;
            }
            /* Custom styling for your content */
          }
        </style>
      </head>
      <body>
        ${printSection.innerHTML}
      </body>
    </html>
  `);
      newWindow.document.close(); // Close the document for further processing
      newWindow.print(); // Trigger the print dialog

      // Close the print window after printing
      newWindow.addEventListener('afterprint', function() {
        newWindow.close();
      });
    },

    updateInvoiceInfo() {
      this.invoiceCreated = false;

      this.loadItems();
    },
    lazyLoad(fn, ms) {
      return new Promise((resolve) => setTimeout(() => resolve(fn(), ms)));
    },
    windowPrint() {
      window.print();
    },

    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(
          JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0);
      });
    },

    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getInvoiceSettings() {
      return await this.$api.get("api/settings/invoice");
    },
    async getInvoiceInfo() {
      const invoiceId = this.$route.params.id;
      return await this.$api.get(
          `api/invoices/${invoiceId}?include=invoiceDetails,account.country`
      );
    },
    async getMilestoneInfo() {
      const milestoneId = this.$route.params.milestoneId;
      return await this.$api.get(
          `api/milestones/${milestoneId}?include=account.country,project.lead,milestoneForwardFromHistories.forwardFrom,milestoneForwardFromHistories.forwardTo,milestoneForwardToHistories.forwardFrom,milestoneForwardToHistories.forwardTo`
      );
    },

    async getActiveAccountInfo() {
      return await this.$api.get("api/accounts/active/all?include=country");
    },
    async getCompany() {
      return await this.$api.get("/api/companies/current");
    },
    async loadItems() {
      try {
        this.invoiceDescription = [];
        const [
          getSetting,
          accounts,
          invoiceSettings,
          invoiceInfo,
          milestoneInfo,
          company,
        ] = await Promise.all([
          this.getSetting({
            select: "account_id",
          }),
          this.getActiveAccountInfo(),
          this.getInvoiceSettings(),
          this.getInvoiceInfo(),
          this.getMilestoneInfo(),
          this.getCompany(),
        ]);

        const today = new Date();

        this.milestoneInfo = milestoneInfo?.data?.data;
        this.invoiceInfo = invoiceInfo?.data?.data;

        this.invoiceCode = invoiceSettings?.data?.data?.invoice_code;
        this.invoicePrefix = invoiceSettings?.data?.data?.invoice_prefix;
        this.invoiceId = this.invoiceInfo?.invoice_no;

        this.issueDate = this.invoiceInfo?.issue_date;

        this.dueDate = this.invoiceInfo?.due_date;
        this.remarks = this.invoiceInfo?.remarks;
        this.invoiceData = {
          items: this.invoiceInfo?.invoiceDetails?.data.map((item) => ({
            itemTitle: item?.title,
            cost: item?.amount,
            qty: item?.quantity,
            price: item?.amount,
            description: item.description,
          })),
        };

        this.discountAmount = this.invoiceInfo?.discount_amount || 0;
        this.vatAmount = this.invoiceInfo?.vat_amount;
        this.taxAmount = this.invoiceInfo?.tax_amount;

        this.currency = this.milestoneInfo?.currency;

        this.officeAddress = company?.data?.data?.address;
        this.officeEmail = company?.data?.data?.email;
        this.companyName = company?.data?.data?.name;
        this.officeMobile = company?.data?.data?.contact_no;


        if (this.invoiceInfo?.account) {
          this.accountInfo = this.invoiceInfo?.account?.data;
        } else {
          const defaultAccId = getSetting?.data?.data?.account_id;
          this.accountInfo = (accounts?.data?.data || []).find((item) => {
            if (item?.id == defaultAccId) {
              return item;
            }
          });
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async updateInvoice(invoiceItems) {
      const invoiceId = this.$route.params.id;
      return await this.$api.put(`api/invoices/${invoiceId}`, {
        tax_amount: this.taxAmount,
        vat_amount: this.vatAmount,
        discount_amount: this.discountAmount,
        sub_total_amount: this.subTotalAmount,
        grand_total_amount: this.grandTotalAmount,
        remarks: this.remarks,
        due_date: this.dueDate,
        items: invoiceItems,
        issue_date: this.issueDate,
      });
    },
    milestoneInvoiceValidationForm: async function () {
      this.$refs.milestoneInvoiceValidation.validate().then(async (success) => {
        if (success) {
          try {
            let invoiceItems = [];

            this.invoiceData?.items.forEach((item) => {
              let data = {};
              data.title = item?.itemTitle;
              data.amount = item?.cost;
              data.quantity = item?.qty;
              data.total_amount = item?.cost * item?.qty;
              data.description = item?.description;
              invoiceItems.push(data);
            });

            try {
              const [invoiceInfo] = await Promise.all([
                this.updateInvoice(invoiceItems),
              ]);

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Invoice Updated Successfully",
                },
              });
            } catch (error) {
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              if (error?.response?.data?.errors) {
                this.$refs.milestoneInvoiceValidation.setErrors(
                    error?.response?.data?.errors
                );
              }
            }
          } catch (error) {
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.milestoneInvoiceValidation.setErrors(
                  error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.custom-width {
  max-width: 25rem !important;
}
</style>
